import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// components
import { ItemCard } from './';

// for firebase
import { FirebaseContext } from '../components/Firebase';

const Collection = () => {
  // states
  const [data, setData] = useState([]);
  const [downloaded, setDownloaded] = useState(false);

  // helper function to render shop items
  const renderShopItems = () =>
    data.map((item, idx) => (
      <Col md key={idx} style={{ margin: 40 }}>
        <ItemCard item={item} />
      </Col>
    ));

  // helper method to download products
  const getProducts = firebase => {
    // clear items
    setData([]);
    // listen to products collection in db
    firebase
      .getProducts()
      .then(snapshot => {
        // set items in state
        setData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      })
      .catch(err => console.log(err));
  };

  return (
    <FirebaseContext.Consumer>
      {firebase => {
        // check if didnt download products yet
        if (!downloaded) {
          setDownloaded(true);
          getProducts(firebase);
        }

        return (
          <div style={styles.container}>
            <Container>
              <Row md={4} xs={10} className='justify-content-md-center'>
                {renderShopItems()}
              </Row>
            </Container>
          </div>
        );
      }}
    </FirebaseContext.Consumer>
  );
};

const styles = {
  container: {
    marginTop: 40,
    backgroundColor: '#444',
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
};

export default Collection;
