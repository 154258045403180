import React, { useState } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import FilledButton from './FilledButton';

// for firebase
import { FirebaseContext } from '../components/Firebase';

const Contact = () => {
  // states
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('Business Inquiry');
  const [body, setBody] = useState('');

  // helper function to handle form submit
  const handleSubmit = (e, firebase) => {
    e.preventDefault();

    // check if all valid
    if (email !== '' && reason !== '' && body !== '') {
      // clear states
      setEmail('');
      setBody('');

      // send email
      firebase
        .sendEmail(email, reason, body)
        .then(_ => console.log('email successfully sent'))
        .catch(err => console.log(err));
    }
  };

  return (
    <FirebaseContext.Consumer>
      {firebase => {
        return (
          <div style={{ padding: 40 }}>
            <Container style={styles.container}>
              <header style={styles.h1} className='item-h1' id='contact'>
                Contact Us!
              </header>
              <Row xs={1} className='justify-content-md-center' style={{ marginTop: 40 }}>
                <ScrollAnimation animateIn='zoomInUp'>
                  <Form>
                    <Form.Group controlId='contact.ControlInput1'>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type='email'
                        placeholder='name@example.com'
                      />
                    </Form.Group>
                    <Form.Group controlId='contact.ControlSelect1'>
                      <Form.Label>Reason for contacting</Form.Label>
                      <Form.Control
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                        as='select'
                      >
                        <option>Business Inquiry</option>
                        <option>Product Support</option>
                        <option>General Question</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='contact.ControlTextarea1'>
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        value={body}
                        onChange={e => setBody(e.target.value)}
                        as='textarea'
                        rows='3'
                      />
                    </Form.Group>
                    <FilledButton onClick={e => handleSubmit(e, firebase)}>SUBMIT</FilledButton>
                  </Form>
                </ScrollAnimation>
              </Row>
            </Container>
          </div>
        );
      }}
    </FirebaseContext.Consumer>
  );
};

// styles
const styles = {
  container: {
    padding: 0,
    textAlign: 'center',
  },
  h1: {
    fontSize: '2.8vmax',
    fontWeight: 700,
  },
};

export default Contact;
