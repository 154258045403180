import React from 'react';

const Footer = () => {
  return <div style={styles.container}></div>;
};

// styles
const styles = {
  container: {
    backgroundColor: '#444',
    color: '#fff',
    height: 200,
  },
};

export default Footer;
