import React, { useState } from 'react';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';

// for firebase
import { FirebaseContext } from '../components/Firebase';

const Login = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // helper function to handle form validation
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  // helper function to handle form submission
  function handleSubmit(event, firebase) {
    // prevent default form action
    event.preventDefault();

    // attempt to login
    firebase
      .login(email, password)
      .then(_ => props.history.push('/admin'))
      .catch(err => setError(err.message));
  }

  return (
    <FirebaseContext.Consumer>
      {firebase => {
        return (
          <div className='Login'>
            <form onSubmit={e => handleSubmit(e, firebase)}>
              <FormGroup controlId='email' bsSize='large'>
                <FormLabel>Email</FormLabel>
                <FormControl
                  autoFocus
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId='password' bsSize='large'>
                <FormLabel>Password</FormLabel>
                <FormControl
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type='password'
                />
              </FormGroup>
              <Button block bssize='large' disabled={!validateForm()} type='submit'>
                Login
              </Button>
              <p style={{ display: error === '' ? 'none' : 'block', ...styles.error }}>{error}</p>
            </form>
          </div>
        );
      }}
    </FirebaseContext.Consumer>
  );
};

// styles
const styles = {
  error: {
    color: 'red',
    marginTop: 20,
  },
};

export default Login;
