import React from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

// pages
import { Home, Login, Admin } from './pages';

// context, firebase
import Firebase, { FirebaseContext } from './components/Firebase';

function App() {
  // connect Login component to router
  const LoginWithRouter = withRouter(Login);

  // connect Admin component to router
  const AdminWithRouter = withRouter(Admin);

  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/login' component={LoginWithRouter} />
          <Route path='/admin' component={AdminWithRouter} protected />
        </Switch>
      </Router>
    </FirebaseContext.Provider>
  );
}

export default App;
