import React from 'react';
import { useState } from 'react';

const OutlinedButton = props => {
  // states
  const [focused, setFocused] = useState(false);

  return (
    <button
      onMouseLeave={() => setFocused(false)}
      onMouseEnter={() => setFocused(true)}
      style={{ marginTop: 10, ...(focused ? styles.buttonHovered : styles.buttonBlurred) }}
      onClick={() => {
        // get element to scroll to
        const e = document.getElementById('shop');
        e.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }}
    >
      {props.children}
    </button>
  );
};

// styles
const styles = {
  buttonBlurred: {
    color: '#fff',
    backgroundColor: 'Transparent',
    outline: 'none',
    border: 'solid',
    borderWidth: 2,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    fontWeight: 600,
  },
  buttonHovered: {
    color: '#444',
    backgroundColor: '#fff',
    outline: 'none',
    border: 'solid',
    borderWidth: 2,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    fontWeight: 600,
    boxShadow: '5px 5px 15px #262626, -5px -5px 15px #626262',
  },
};

export default OutlinedButton;
