import app from 'firebase/app';
import firebase from 'firebase';

// config for firebase credentials
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  // constructor
  constructor() {
    app.initializeApp(config);
  }

  // expose method for logging in
  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  // expose method for getting current user
  currentUser() {
    return firebase.auth().currentUser;
  }

  // expose method for adding new product to db
  addProduct(title, description, tags, imageUrl, price, square, images) {
    return firebase
      .firestore()
      .collection('products')
      .doc()
      .set({
        title: title,
        description: description,
        tags: tags.split(','), // to make tags an array object
        imageUrl: imageUrl,
        price: price * 100, // to store price in cents (for database/js precision)
        square: square,
        images: images,
      });
  }

  // expose method for getting products from db
  getProducts() {
    return firebase.firestore().collection('products').get();
  }

  // expose method for deleting item from db
  deleteProduct(id) {
    return firebase.firestore().collection('products').doc(id).delete();
  }

  // expose method for sending new support email
  sendEmail(email, reason, body) {
    return firebase.firestore().collection('emails').doc().set({
      email: email,
      reason: reason,
      body: body,
    });
  }
}

export default Firebase;
