import React from 'react';

// components
import { MainBlock, MiddleBlock, Navbar, Collection, Contact, Footer } from '../components';

const Home = () => {
  return (
    <>
      {/** top nav bar */}
      <Navbar />
      {/** leading block on page */}
      <MainBlock />
      {/** for containerized icon under main block */}
      <MiddleBlock />
      {/** for collection block (shop) */}
      <Collection />
      {/** for contact section */}
      <Contact />
      {/** for footer block at bottom */}
      <Footer />
    </>
  );
};

// expose this component
export default Home;
