import React from 'react';
import { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

// import checkout
import Checkout from './Checkout';

const ItemCard = props => {
  // get item properties (passed in via props)
  const { title, description, tags, price, imageUrl, square, images } = props.item;

  // state for hovers
  const [focused, setFocused] = useState(false);

  // state for showing modal
  const [modalShow, setModalShow] = useState(false);

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => setModalShow(true)}>
      <ScrollAnimation animateIn='slideInLeft'>
        <div
          onMouseEnter={() => setFocused(true)}
          onMouseLeave={() => setFocused(false)}
          style={focused ? styles.containerFocused : styles.containerBlurred}
        >
          <img style={styles.img} src={imageUrl} alt={title} />
          <p style={styles.tags}>{tags.join(', ')}</p>
          <header style={styles.h1} className='item-h1'>
            {title}
          </header>
          <p style={styles.price}>${price / 100}</p>
        </div>
      </ScrollAnimation>
      <div onClick={e => e.stopPropagation()}>
        <Checkout
          square={square}
          images={images}
          title={title}
          description={description}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
};

// styles
const styles = {
  containerBlurred: {
    backgroundColor: '#fff',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '5px 5px 15px #3d3d3d, -5px -5px 15px #4b4b4b',
  },
  containerFocused: {
    backgroundColor: '#fff',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '15px 15px 30px #292929, -15px -15px 30px #5f5f5f',
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
  },
  tags: {
    marginTop: 20,
    color: '#888',
    fontSize: '0.7em',
  },
  h1: {
    fontWeight: 700,
    fontSize: '1.4em',
    marginLeft: 20,
    marginRight: 20,
  },
  price: {
    marginTop: 20,
    color: '#888',
  },
};

export default ItemCard;
