import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

// assets
import topBanner from '../assets/topBanner.jpeg';

export default () => {
  return (
    <Navbar collapseOnSelect expand='md' className='navbar' bg='white' sticky='top'>
      <Navbar.Brand href='#home'>
        <img alt='' src={topBanner} width='200' height='60' className='d-inline-block align-top' />{' '}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav style={styles.leftNav} className='ml-auto'>
          <Nav.Link style={styles.navLink} href='#shop'>
            Our Collection
          </Nav.Link>
          <Nav.Link style={styles.navLink} href='#contact'>
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

// styles
const styles = {
  navLink: {
    fontSize: '1.25em',
    marginRight: 40,
  },
  leftNav: {
    marginRight: 20,
  },
};
