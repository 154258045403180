import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { OutlinedButton } from '.';

const MainBlock = () => {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <ScrollAnimation animateIn='zoomInLeft'>
          <header style={styles.h1} className='main-block-header'>
            QUALITY, HANDMADE FURNITURE
          </header>
        </ScrollAnimation>

        <br />
        <ScrollAnimation animateIn='fadeInLeft'>
          <OutlinedButton>COLLECTION</OutlinedButton>
        </ScrollAnimation>
      </div>
    </div>
  );
};

// styles
const styles = {
  container: {
    backgroundColor: '#444',
    color: '#fff',
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  innerContainer: {},
  h1: {
    fontSize: '3vmax',
  },
};

export default MainBlock;
