import React from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';

const Checkout = props => {
  // helper method to safely render images into carousel
  const renderImages = () => {
    if (props.images !== undefined) {
      return props.images.map(image => (
        <Carousel.Item>
          <img className='d-block w-50' style={{ margin: 'auto' }} src={image} alt='Product' />
        </Carousel.Item>
      ));
    }
  };
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={styles.modalBody}>
        <Carousel>{renderImages()}</Carousel>
        <p style={styles.description}>{'description' in props ? props.description : ''}</p>
        <a rel='noopener noreferrer' target='_blank' href={props.square} style={styles.checkoutBtn}>
          Buy now
        </a>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// styles
const styles = {
  modalBody: {
    textAlign: 'center',
  },
  description: {
    marginTop: 25,
  },
  checkoutBtn: {
    marginTop: 20,
    display: 'inline-block',
    fontSize: 18,
    height: 48,
    paddingTop: 10,
    paddingLeft: 48,
    paddingRight: 48,
    color: 'white',
    minWidth: 200,
    backgroundColor: '#000000',
    borderRadius: 4,
    textAlign: 'center',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1) inset',
  },
};

export default Checkout;
