import React from 'react';

// assets
import middleIcon from '../assets/middleIcon.jpg';

const MiddleBlock = () => {
  return (
    <div style={styles.container}>
      <img alt='' src={middleIcon} width='100' height='95' className='d-inline-block align-top' />
      <br />
      <header style={styles.h3} className='h3' id='shop'>
        Our Collection
      </header>
    </div>
  );
};
// styles
const styles = {
  container: {
    textAlign: 'center',
    width: '100%',
    marginTop: 40,
  },
  h3: {
    marginTop: 30,
    fontSize: '1.25vmax',
    fontWeight: 600,
  },
};

export default MiddleBlock;
