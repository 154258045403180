import React, { useState } from 'react';
import { Form, Button, Badge, Table } from 'react-bootstrap';

// for firebase
import { FirebaseContext } from '../components/Firebase';

const Admin = props => {
  // states
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [price, setPrice] = useState('');
  const [square, setSquare] = useState('');
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [listening, setListening] = useState(false);

  // helper method to render items in table
  const renderItems = firebase => {
    return items.map((item, idx) => (
      <tr key={idx}>
        <td>
          <Button onClick={() => deleteProduct(firebase, item.id)} variant='danger'>
            DELETE
          </Button>
        </td>
        <td>{item.id.substr(0, 6)}</td>
        <td>{item.title}</td>
        <td>
          {(() => {
            return item.tags.map(tag => (
              <>
                <Badge pill variant='primary'>
                  {tag}
                </Badge>{' '}
              </>
            ));
          })()}
        </td>
        <td>
          <a href={item.imageUrl}>{item.imageUrl}</a>
        </td>
        <td>
          <mark style={styles.dollar}>${item.price / 100}</mark>
        </td>
      </tr>
    ));
  };

  // helper method to render tags
  const renderTags = () => {
    return tags.split(',').map(tag => (
      <>
        <Badge pill variant='primary'>
          {tag}
        </Badge>{' '}
      </>
    ));
  };

  // helper method to validate form
  const validateForm = () => {
    return (
      title !== '' &&
      tags.split(',').length > 0 &&
      imageUrl !== '' &&
      square !== '' &&
      !isNaN(price) &&
      parseFloat(price) > 0
    );
  };

  // helper method to handle form submission
  const handleSubmit = (e, firebase) => {
    // prevent default
    e.preventDefault();

    // add item to database
    firebase
      .addProduct(title, description, tags, imageUrl, price, square, [
        imageUrl,
        ...images
          .split('\n')
          .map(url => url.trim())
          .filter(url => url !== ''),
      ])
      .then(_ => {
        // clear fields
        setTitle('');
        setTags('');
        setImageUrl('');
        setPrice('');
        setSquare('');

        // download new products
        getProducts(firebase);
      })
      .catch(err => console.log(err));
  };

  // helper method to download products
  const getProducts = firebase => {
    // clear items
    setItems([]);
    // listen to products collection in db
    firebase
      .getProducts()
      .then(snapshot => {
        // set items in state
        setItems(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      })
      .catch(err => console.log(err));
  };

  // helper method to delete product
  const deleteProduct = (firebase, id) => {
    firebase
      .deleteProduct(id)
      .then(_ => {
        // re-dl products
        getProducts(firebase);
      })
      .catch(err => console.log(err));
  };

  return (
    <FirebaseContext.Consumer>
      {firebase => {
        // check if logged in
        if (firebase.currentUser() && firebase.currentUser() !== undefined) {
          // check if not already listening to product collection
          if (!listening) {
            // set listening and get products
            setListening(true);
            getProducts(firebase);
          }

          return (
            <div style={styles.container}>
              <header style={styles.h1}>ADD NEW ITEM</header>
              <Form onSubmit={e => handleSubmit(e, firebase)}>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Item Title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    placeholder='Enter item title'
                  />
                  <Form.Text className='text-muted'>
                    This can be anything, just try to keep it short for styling reasons.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Item Description</Form.Label>
                  <Form.Control
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder='Enter item description'
                  />
                  <Form.Text className='text-muted'>This can be anything.</Form.Text>
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Square Link</Form.Label>
                  <Form.Control
                    value={square}
                    onChange={e => setSquare(e.target.value)}
                    placeholder='Enter item title'
                  />
                  <Form.Text className='text-muted'>
                    Make sure this is the exact link, from Square Dashboard, or it will not work.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    value={tags}
                    onChange={e => setTags(e.target.value)}
                    placeholder='Enter tags'
                  />
                  <Form.Text className='text-muted'>
                    Comma seperated, ex: furniture,shelf,cabinet
                  </Form.Text>
                  {renderTags()}
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Thumbnail Image</Form.Label>
                  <Form.Control
                    value={imageUrl}
                    onChange={e => setImageUrl(e.target.value)}
                    placeholder='Enter image link'
                  />
                  <Form.Text className='text-muted'>
                    Upload your image to Imgur, then paste the link to the .png/.jpg here. Should
                    look like: https://i.imgur.com/GMVVGW7.jpeg
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Form.Label>More Images</Form.Label>
                  <Form.Control onChange={e => setImages(e.target.value)} as='textarea' rows='3' />
                  <Form.Text className='text-muted'>
                    Paste your Imgur links, line by line (ON SEPERATE LINES!). There is no need to
                    re-add the one you pasted above, that will be added for you.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                    placeholder='Enter price'
                  />
                  <Form.Text className='text-muted'>
                    Must be a number, no dollar sign. Ex: 19.99
                  </Form.Text>
                </Form.Group>
                <Button block bssize='large' disabled={!validateForm()} type='submit'>
                  Add Item
                </Button>
              </Form>
              <header style={{ marginTop: 50, ...styles.h1 }}>ITEMS IN DATABASE</header>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Tags</th>
                    <th>Image</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>{renderItems(firebase)}</tbody>
              </Table>
            </div>
          );
        } else {
          // push back
          props.history.push('/login');
        }
      }}
    </FirebaseContext.Consumer>
  );
};

// styles
const styles = {
  container: {
    padding: 50,
  },
  h1: {
    fontWeight: 700,
    fontSize: '1.8em',
    marginBottom: 50,
  },
  dollar: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#AAFFAA',
    borderRadius: 10,
  },
};

export default Admin;
