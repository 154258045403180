import React from 'react';
import { useState } from 'react';

const FilledButton = props => {
  // states
  const [focused, setFocused] = useState(false);

  return (
    <button
      onMouseLeave={() => setFocused(false)}
      onMouseEnter={() => setFocused(true)}
      style={{ marginTop: 10, ...(focused ? styles.buttonHovered : styles.buttonBlurred) }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

// styles
const styles = {
  buttonBlurred: {
    color: '#444',
    backgroundColor: 'Transparent',
    outline: 'none',
    borderColor: '#444',
    border: 'solid',
    borderWidth: 2,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 80,
    paddingRight: 80,
    fontWeight: 600,
  },
  buttonHovered: {
    color: 'white',
    backgroundColor: '#444',
    outline: 'none',
    border: 'none',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 82,
    paddingRight: 82,
    fontWeight: 600,
    boxShadow: '15px 15px 33px #ababab, -15px -15px 33px #ffffff',
  },
};

export default FilledButton;
